import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  ref: "file",
  style: {
    "display": "none"
  },
  type: "file",
  accept: "image/*",
  capture: "user"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", _hoisted_2, null, 512)]);
}